<template>
  <b-modal
    size="xl"
    modal-class="p-0"
    id="modalRegister"
    centered
    hide-header
    hide-footer
  >
    <!-- Modal body -->
    <div class="modal-body">
      <button
        type="button"
        @click="$bvModal.hide('modalRegister')"
        class="close"
        data-dismiss="modal"
      >
        <img src="@/assets/img/ic-close.svg" alt="" />
      </button>
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="p-4 pb-0">
              <h2>Sign up</h2>
              <p>
                With a Whizliz account, you enjoy easy checkout, exclusive
                offers, your personal wish list.
              </p>
              <!-- Email -->
              <MyInputFormText
                id="email"
                label="Email"
                type="email"
                v-model="$v.email.$model"
                placeholder="Email"
                :classComponent="{
                  'is-invalid-custom': $v.email.$error,
                  'is-valid-custom': !$v.email.$invalid,
                }"
                :invalidMessage="[
                  !$v.email.required ? 'Butuh email kamu nih' : null,
                  !$v.email.email ? 'Masukan format email kamu' : null,
                ]"
                redAsterisk="true"
              />
              <!-- Full Name -->
              <MyInputFormText
                id="full-name"
                label="Full Name"
                v-model="$v.fullName.$model"
                placeholder="Full Name"
                :classComponent="{
                  'is-invalid-custom': $v.fullName.$error,
                  'is-valid-custom': !$v.fullName.$invalid,
                }"
                :invalidMessage="[
                  !$v.fullName.required ? 'Butuh nama lengkap kamu nih' : null,
                  !$v.fullName.namePattern ? 'Masukin text yu!' : null,
                ]"
                redAsterisk="true"
              />
              <!-- Phone -->
              <MyInputFormText
                id="phone-number"
                label="Phone Number"
                v-model="$v.phone.$model"
                placeholder="Phone Number"
                :classComponent="{
                  'is-invalid-custom': $v.phone.$error,
                  'is-valid-custom': !$v.phone.$invalid,
                }"
                :invalidMessage="[
                  !$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
                  !$v.phone.phonePattern
                    ? 'Nomor telepon kamu tidak valid :('
                    : null,
                  !$v.phone.minLength && $v.phone.phonePattern
                    ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.phone.$params.minLength.min}.`
                    : null,
                  !$v.phone.maxLength && $v.phone.phonePattern
                    ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.phone.$params.maxLength.max}.`
                    : null,
                ]"
                redAsterisk="true"
                phonePrefix="true"
              />
              <!-- TODO code untuk validasi umur min 17 thn belum optimal  -->
              <!-- Birth Date -->
              <MyInputFormText
                id="birthday-date"
                label="Birthdate"
                type="date"
                v-model="$v.birthDate.$model"
                placeholder="Birthday Date"
                :classComponent="{
                  'is-invalid-custom': $v.birthDate.$error,
                  'is-valid-custom': !$v.birthDate.$invalid,
                }"
                :invalidMessage="[
                  !$v.birthDate.required
                    ? 'Butuh tanggal lahir kamu nih'
                    : null,
                  !$v.birthDate.maxValue
                    ? 'Usia kamu belum mencapai 17 thn :('
                    : null,
                ]"
                :max="getMaxBirthDate"
                redAsterisk="true"
              />
              <!-- Gender -->
              <div class="form-group mb-0">
                <div class="d-flex align-items-center">
                  <p class="mr-4 mb-0">
                    <label>Gender <span class="text-danger">*</span></label>
                  </p>
                  <div class="styled-radio">
                    <label class="container d-flex align-items-center mb-2">
                      Male
                      <input
                        v-model="$v.gender.$model"
                        type="radio"
                        value="m"
                        name="gender"
                        required
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="styled-radio">
                    <label class="container d-flex align-items-center mb-2">
                      Female
                      <input
                        v-model="$v.gender.$model"
                        type="radio"
                        value="f"
                        name="gender"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-4 pt-0">
              <p class="mt-lg-5">Secure your account with a strong password</p>
              <!-- Password -->
              <MyInputFormText
                id="password"
                label="Password"
                type="password"
                v-model="$v.password.$model"
                placeholder="Password"
                :classComponent="{
                  'is-invalid-custom': $v.password.$error,
                  'is-valid-custom': !$v.password.$invalid,
                }"
                :invalidMessage="[
                  !$v.password.required ? 'Butuh kata sandi kamu nih' : null,
                  !$v.password.passwordPattern
                    ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                    : null,
                  !$v.password.minLength
                    ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${$v.password.$params.minLength.min}.`
                    : null,
                ]"
                redAsterisk="true"
              />
              <!-- Confirmation Password -->
              <MyInputFormText
                id="confirm-password"
                label="Confirm Password"
                type="password"
                v-model="$v.confirmPassword.$model"
                placeholder="Confirm Password"
                :classComponent="{
                  'is-invalid-custom': $v.confirmPassword.$error,
                  'is-valid-custom': !$v.confirmPassword.$invalid,
                }"
                :invalidMessage="[
                  !$v.confirmPassword.required
                    ? 'Butuh konfirmasi kata sandi kamu nih'
                    : null,
                  !$v.confirmPassword.sameAsPassword &&
                  $v.confirmPassword.required
                    ? 'Kata sandi kamu harus cocok ya ..'
                    : null,
                ]"
                redAsterisk="true"
              />
              <div class="checkout-page">
                <div class="styled-checkbox mt-2">
                  <label class="container-checkbox">
                    I’ve read and agreed to Whizliz&apos;s
                    <a
                      href="/static/terms-conditions"
                      class="bold tnc-url"
                      target="_blank"
                      >Terms &amp; Conditions</a
                    >
                    <input type="checkbox" v-model="agreeTnc" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="text-center mt-4">
                <button class="btn btn-wz-proceed">
                  CREATE ACCOUNT
                  <font-awesome v-if="isLoading" icon="spinner" spin />
                </button>
              </div>
              <div class="login-socmed text-center">
                <p>or sign up with</p>
                <a href="#" @click="handleClickSignIn()">
                  <img src="@/assets/img/ic-google.svg" alt="" />
                </a>
                <p class="text-register">
                  Already have an Account?
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    v-b-modal="'modalLogin'"
                    @click="$bvModal.hide('modalRegister')"
                    >Sign in</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import MyInputFormText from "@/components/form/MyInputFormText";

import { toaster, regex } from "@/_helpers";
import { userService } from "@/_services";
import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";

import moment from "moment";

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
// Regex for Validation End

export default {
  name: "ModalRegister",
  components: {
    MyInputFormText,
  },
  data() {
    return {
      email: "",
      fullName: "",
      password: "",
      confirmPassword: "",
      birthDate: "",
      gender: "",
      phone: "",

      signUpData: {
        email: "",
        fullName: "",
        password: "",
        confirmPassword: "",
        birthDate: "",
        gender: "",
        phone: "",
      },

      isLoading: false,
      agreeTnc: false,
    };
  },
  computed: {
    // Min age 17 years old
    getMaxBirthDate() {
      let res = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
      return moment.unix(res).format("YYYY-MM-DD");
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    fullName: {
      required,
      namePattern,
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(14),
      phonePattern,
    },
    birthDate: {
      required,
      maxValue(date) {
        // res = 17 years ago
        let max = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
        max = moment.unix(max).toDate();
        date = new Date(date);
        if (date > max) return false;
        return true;
      },
    },
    gender: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
      passwordPattern,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    onSubmit() {
      try {
        this.isLoading = true;
        const {
          email,
          fullName,
          password,
          confirmPassword,
          birthDate,
          gender,
          phone,
        } = this;
        this.$v.$touch();
        const agree = this.agreeTnc;
        // If Form Invalid
        if (this.$v.$invalid) {
          toaster.make("Please fill the form correctly", "danger");
          this.isLoading = false;
        }
        // If Form Valid
        else if (agree) {
          this.assignDataSignup(
            email,
            fullName,
            password,
            confirmPassword,
            birthDate,
            gender,
            phone
          );
          userService.signUp(this.signUpData).then((value) => {
            if (value == "Success, please check your mail") {
              toaster.make(value, "success");
              this.$bvModal.hide("modalRegister");
            } else toaster.make(value, "danger");
            this.isLoading = false;
          });
        } else {
          toaster.make(
            "You haven't agreed to our T&C, please confirm.",
            "warning"
          );
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
      // If Form Valid End

      // userService.getAll().then(function (response) {
      //   alert(response.data.first_name);
      //   return response.data; // now the data is accessable from here.
      // });
    },
    assignDataSignup(
      email,
      fullName,
      password,
      confirmPassword,
      birthDate,
      gender,
      phone
    ) {
      this.signUpData.email = email;
      this.signUpData.fullName = fullName;
      this.signUpData.password = password;
      this.signUpData.confirmPassword = confirmPassword;
      this.signUpData.birthDate = birthDate;
      this.signUpData.gender = gender;
      this.signUpData.phone = /\+62/.test(phone) ? phone : "+62" + phone;
    },
    async handleClickSignIn() {
      try {
        this.loginApp = "google";
        const user = await this.$gAuth.signIn();
        const data = await userService.socialLogin(
          this.loginApp,
          user,
          "login"
        );
        toaster.make("Welcome, " + data.profile_data.full_name, "success");
        return this.$emit("ModalLoginToken", data.access_token);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
:is(.checkout-page, #modalRegister, #modalLogin) .styled-checkbox label {
  font-size: 12px;
  font-weight: initial;
  text-transform: initial;
}
:is(.checkout-page, #modalRegister, #modalLogin) .checkmark {
  top: -1px;
}
</style>