var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"size":"xl","modal-class":"p-0","id":"modalRegister","centered":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"modal-body"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('modalRegister')}}},[_c('img',{attrs:{"src":require("@/assets/img/ic-close.svg"),"alt":""}})]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"p-4 pb-0"},[_c('h2',[_vm._v("Sign up")]),_c('p',[_vm._v(" With a Whizliz account, you enjoy easy checkout, exclusive offers, your personal wish list. ")]),_c('MyInputFormText',{attrs:{"id":"email","label":"Email","type":"email","placeholder":"Email","classComponent":{
                'is-invalid-custom': _vm.$v.email.$error,
                'is-valid-custom': !_vm.$v.email.$invalid,
              },"invalidMessage":[
                !_vm.$v.email.required ? 'Butuh email kamu nih' : null,
                !_vm.$v.email.email ? 'Masukan format email kamu' : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", $$v)},expression:"$v.email.$model"}}),_c('MyInputFormText',{attrs:{"id":"full-name","label":"Full Name","placeholder":"Full Name","classComponent":{
                'is-invalid-custom': _vm.$v.fullName.$error,
                'is-valid-custom': !_vm.$v.fullName.$invalid,
              },"invalidMessage":[
                !_vm.$v.fullName.required ? 'Butuh nama lengkap kamu nih' : null,
                !_vm.$v.fullName.namePattern ? 'Masukin text yu!' : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.fullName.$model),callback:function ($$v) {_vm.$set(_vm.$v.fullName, "$model", $$v)},expression:"$v.fullName.$model"}}),_c('MyInputFormText',{attrs:{"id":"phone-number","label":"Phone Number","placeholder":"Phone Number","classComponent":{
                'is-invalid-custom': _vm.$v.phone.$error,
                'is-valid-custom': !_vm.$v.phone.$invalid,
              },"invalidMessage":[
                !_vm.$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
                !_vm.$v.phone.phonePattern
                  ? 'Nomor telepon kamu tidak valid :('
                  : null,
                !_vm.$v.phone.minLength && _vm.$v.phone.phonePattern
                  ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.phone.$params.minLength.min}.`
                  : null,
                !_vm.$v.phone.maxLength && _vm.$v.phone.phonePattern
                  ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${_vm.$v.phone.$params.maxLength.max}.`
                  : null,
              ],"redAsterisk":"true","phonePrefix":"true"},model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", $$v)},expression:"$v.phone.$model"}}),_c('MyInputFormText',{attrs:{"id":"birthday-date","label":"Birthdate","type":"date","placeholder":"Birthday Date","classComponent":{
                'is-invalid-custom': _vm.$v.birthDate.$error,
                'is-valid-custom': !_vm.$v.birthDate.$invalid,
              },"invalidMessage":[
                !_vm.$v.birthDate.required
                  ? 'Butuh tanggal lahir kamu nih'
                  : null,
                !_vm.$v.birthDate.maxValue
                  ? 'Usia kamu belum mencapai 17 thn :('
                  : null,
              ],"max":_vm.getMaxBirthDate,"redAsterisk":"true"},model:{value:(_vm.$v.birthDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.birthDate, "$model", $$v)},expression:"$v.birthDate.$model"}}),_c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mr-4 mb-0"},[_c('label',[_vm._v("Gender "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"styled-radio"},[_c('label',{staticClass:"container d-flex align-items-center mb-2"},[_vm._v(" Male "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.gender.$model),expression:"$v.gender.$model"}],attrs:{"type":"radio","value":"m","name":"gender","required":""},domProps:{"checked":_vm._q(_vm.$v.gender.$model,"m")},on:{"change":function($event){return _vm.$set(_vm.$v.gender, "$model", "m")}}}),_c('span',{staticClass:"checkmark"})])]),_c('div',{staticClass:"styled-radio"},[_c('label',{staticClass:"container d-flex align-items-center mb-2"},[_vm._v(" Female "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.gender.$model),expression:"$v.gender.$model"}],attrs:{"type":"radio","value":"f","name":"gender"},domProps:{"checked":_vm._q(_vm.$v.gender.$model,"f")},on:{"change":function($event){return _vm.$set(_vm.$v.gender, "$model", "f")}}}),_c('span',{staticClass:"checkmark"})])])])])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"p-4 pt-0"},[_c('p',{staticClass:"mt-lg-5"},[_vm._v("Secure your account with a strong password")]),_c('MyInputFormText',{attrs:{"id":"password","label":"Password","type":"password","placeholder":"Password","classComponent":{
                'is-invalid-custom': _vm.$v.password.$error,
                'is-valid-custom': !_vm.$v.password.$invalid,
              },"invalidMessage":[
                !_vm.$v.password.required ? 'Butuh kata sandi kamu nih' : null,
                !_vm.$v.password.passwordPattern
                  ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                  : null,
                !_vm.$v.password.minLength
                  ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.password.$params.minLength.min}.`
                  : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_c('MyInputFormText',{attrs:{"id":"confirm-password","label":"Confirm Password","type":"password","placeholder":"Confirm Password","classComponent":{
                'is-invalid-custom': _vm.$v.confirmPassword.$error,
                'is-valid-custom': !_vm.$v.confirmPassword.$invalid,
              },"invalidMessage":[
                !_vm.$v.confirmPassword.required
                  ? 'Butuh konfirmasi kata sandi kamu nih'
                  : null,
                !_vm.$v.confirmPassword.sameAsPassword &&
                _vm.$v.confirmPassword.required
                  ? 'Kata sandi kamu harus cocok ya ..'
                  : null,
              ],"redAsterisk":"true"},model:{value:(_vm.$v.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.confirmPassword, "$model", $$v)},expression:"$v.confirmPassword.$model"}}),_c('div',{staticClass:"checkout-page"},[_c('div',{staticClass:"styled-checkbox mt-2"},[_c('label',{staticClass:"container-checkbox"},[_vm._v(" I’ve read and agreed to Whizliz's "),_c('a',{staticClass:"bold tnc-url",attrs:{"href":"/static/terms-conditions","target":"_blank"}},[_vm._v("Terms & Conditions")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreeTnc),expression:"agreeTnc"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agreeTnc)?_vm._i(_vm.agreeTnc,null)>-1:(_vm.agreeTnc)},on:{"change":function($event){var $$a=_vm.agreeTnc,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agreeTnc=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agreeTnc=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agreeTnc=$$c}}}}),_c('span',{staticClass:"checkmark"})])])]),_c('div',{staticClass:"text-center mt-4"},[_c('button',{staticClass:"btn btn-wz-proceed"},[_vm._v(" CREATE ACCOUNT "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)]),_c('div',{staticClass:"login-socmed text-center"},[_c('p',[_vm._v("or sign up with")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleClickSignIn()}}},[_c('img',{attrs:{"src":require("@/assets/img/ic-google.svg"),"alt":""}})]),_c('p',{staticClass:"text-register"},[_vm._v(" Already have an Account? "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modalLogin'),expression:"'modalLogin'"}],attrs:{"href":"#","data-dismiss":"modal","data-toggle":"modal"},on:{"click":function($event){return _vm.$bvModal.hide('modalRegister')}}},[_vm._v("Sign in")])])])],1)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }